"use client"

import "@/app/_assets/styles/app.css.ts"
import { ErrorPageBody } from "@/app/_components/ErrorPageBody"
import { Footer } from "@/app/_components/Footer"
import { Header } from "@/app/_components/Header"
import * as Sentry from "@sentry/nextjs"
import { useEffect } from "react"

export default function GlobalError({
  error
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  // layout.tsxレベルのエラーが起きたときのエラーページ。Storeなど削ることで極力動作するようにしている。
  return (
    <html lang="ja">
      <body>
        <Header />
        <ErrorPageBody statusCode={500} />
        <Footer />
      </body>
    </html>
  )
}
